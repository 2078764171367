var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"web-links"},[(!_vm.currentView)?_c('div',[_c('filter-levels',{attrs:{"filter-type":"web_links","extra-menu-items":[_vm.ExtraSettingsMenuItems.AdvancedSettings, _vm.ExtraSettingsMenuItems.CommonRules, _vm.ExtraSettingsMenuItems.VirtualNew]},on:{"changeCurrentView":_vm.handleCurrentView}}),_c('div',{staticClass:"main-filter-settings mt-5"},[_c('a-card',{attrs:{"title":_vm.$t('field_special_settings_title')}},[(Array.isArray(_vm.$store.state.chatState.configCopy.accept_http_links_whitelist))?_c('chips-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.$store.state.chatState.configCopy,
              'key': 'accept_http_links_whitelist',
              'hasAnchors': true,
            }
          }}}):_c('black-white-list-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.$store.state.chatState.configCopy,
              'key': 'accept_http_links_whitelist',
              'hasAnchors': true,
            }
          }}}),_c('a-divider'),_c('strict-mode',{attrs:{"filter-type":"web_links","store-path":"$store.state.chatState.configCopy","pick-fields":[_vm.StrictModeFields.Strict_mode]}}),_c('ignore-caption',{attrs:{"filter-type":"web_links","store-path":"$store.state.chatState.configCopy"}}),_c('handle-user-bio',{attrs:{"filter-type":"web_links","store-path":"$store.state.chatState.configCopy"}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_common_settings_title')}},[_c('common-settings',{attrs:{"filter-type":"web_links","store-path":"$store.state.chatState.configCopy"}})],1)],1)],1):_c('div',[(_vm.currentView === _vm.ExtraSettingsMenuItems.AdvancedSettings)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"advanced_settings"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'accept_http_links_from_top_100',
                'hasAnchor': true
              }
            }}}),(_vm.$store.getters.isRuChat || _vm.$store.state.chatState.configCopy.accept_http_links_from_top_ru_100)?_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'accept_http_links_from_top_ru_100',
                'hasAnchor': true
              }
            }}}):_vm._e(),_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'web_links_dont_check_ips',
                'hasAnchor': true
              }
            }}})]},proxy:true}],null,false,1344364321)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.VirtualNew)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"virtual_new"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('virtual-new-settings',{attrs:{"filter-type":"web_links","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,246485993)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.CommonRules)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"common_rules"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('common-rules-settings',{attrs:{"filter-type":"web_links","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,2042901348)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }